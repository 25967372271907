import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Privacy from '../views/Privacy'
import MemoryMap from '../views/map_content/MemoryMap'
import CartridgeROMInfo from '../views/map_content/CartridgeROMInfo'
import VideoChipInfo from '../views/map_content/VideoChipInfo'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/memorymap/:console',
    name: 'memorymap',
    props: true,
    component: MemoryMap
  },
  {
    path: '/cartridgerominfo/:console',
    name: 'cartridgerominfo',
    props: true,
    component: CartridgeROMInfo
  },
  {
    path: '/videochipinfo/:console',
    name: 'videochipinfo',
    props: true,
    component: VideoChipInfo
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router