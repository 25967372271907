<template>
  <router-link :to="{ name: linkdir, params: {console: console} }">
    <button class="sidebar-child">
      {{ text }}
    </button>
  </router-link>
</template>

<script>
  export default {
    name: 'SidebarChildElement',
    props: {
      text: String,
      console: String,
      linkdir: String
    }
  }
</script>

<style scoped>
  button {
    display: block;
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: 0;
    text-align: left;
    padding: 5px 10px;
    font-size: 17px;
  }
  a:link,
  a:visited,
  a:active,
  a:hover {
    text-decoration: none;
  }

  .sidebar-child {
    background: #ccc;
    padding: 5px 15px;
  }
</style>