<template>
    <button @click="toggleSidebar"></button>
</template>

<script>
  export default {
    name: 'SidebarButton',
    methods: {
      toggleSidebar() {
        this.$emit('toggled-sidebar')
      },
    },
  }
</script>

<style scoped>
  button {
    width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #2c3e50 20%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 40%, #2c3e50 40%, #2c3e50 60%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 80%, #2c3e50 80%, #2c3e50 100%);
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 13px 10px 13px 16px;
  }
</style>