<template>
  <h3 class="pagetitle">Privacy policy</h3>
</template>

<script>
  export default {
    name: 'Privacy',
  }
</script>

<style scoped>
  #app .content > * {
    display: block;
  }
  .pagetitle {
    margin-bottom: 0
  }
  p:first-of-type {
    margin-top: 5px;
  }
  .about_version {
    margin-top: 30px;
  }
</style>