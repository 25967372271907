<template>
  <h3 class="pagetitle">Retro Memory Viewer</h3>
  <p>An easy assistant to your old hardware's memory!</p>
  <p class="about_version">Version 0.0.3</p>
  <p>Created and maintained by <a href="https://gitlab.com/doubl33n" target="_blank">Doubl33n</a></p>
</template>

<script>
  export default {
    name: 'About',
  }
</script>

<style scoped>
  .pagetitle {
    margin-bottom: 0
  }
</style>