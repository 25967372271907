<template>
  <button class="sidebar-parent" @click="toggleSidebarParent">
    {{ text }}
    <i class="fa" :class="showSidebarChildren ? 'fa-angle-up' : 'fa-angle-down'"></i>
  </button>
  <div class="sidebar-dropdown" v-show="showSidebarChildren">
    <SidebarChildElement text="Memory Map" :console="console" linkdir="memorymap" />
    <SidebarChildElement text="Cartridge ROM Info" :console="console" linkdir="cartridgerominfo" />
    <SidebarChildElement text="Video Chip Info" :console="console" linkdir="videochipinfo" />
  </div>
</template>

<script>
  import SidebarChildElement from "./SidebarChildElement"

  export default {
    name: 'SidebarParentElement',
    props: {
      text: String,
      console: String
    },
    components: {
      SidebarChildElement
    },
    data() {
      return {
        showSidebarChildren: false
      }
    },
    methods: {
      toggleSidebarParent() {
        this.showSidebarChildren = !this.showSidebarChildren
      },
    },
  }
</script>

<style scoped>
  button {
    display: block;
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: 0;
    text-align: left;
    padding: 5px 10px;
    font-size: 17px;
  }

  button i {
    float: right;
    position: relative;
    top: 5px;
  }

  .sidebar-parent {
    background: #bbb;
  }

  .sidebar-dropdown {
    background: #ccc
  }
</style>