<template>
  <div class="sidebar">
    <SidebarParentElement text="Commodore 64" console="c64" />
    <SidebarParentElement text="Gameboy" console="gameboy" />    
  </div>
</template>

<script>
  import SidebarParentElement from "./SidebarParentElement"
  
  export default {
    name: 'Sidebar',
    props: {
      showSidebar: Boolean
    },
    components: {
      SidebarParentElement
    }
  }
</script>

<style scoped>
  .sidebar {
    position: absolute;
    width: 250px;
    left: 0;
    height: calc(100vh - 66px);
    background: #bbb;
  }
</style>