<template>
  <footer>
    <router-link to="/about">About</router-link>
    <router-link to="/privacy">Privacy policy</router-link>
    <a href="https://gitlab.com/doubl33n/retro_memory_viewer">GitLab Repository</a>
  </footer>  
</template>

<style scoped>
  a {
    color: #333;
    margin: 0px 5px;
  }

  footer {
    margin-top: 30px;
    text-align: center;
    height: 50px;
  }
</style>