<template>
  <Header title="Retro Memory Viewer" @toggled-sidebar="toggleSidebar"/>
  <Sidebar v-show="showSidebar"/>
  <div class="content">
    <router-view></router-view>
  </div>
  <Footer />
</template>

<script>
import Header from './components/Header'
import Sidebar from './components/sidebar/Sidebar'
import Footer from './components/Footer'

export default {
  name: 'App',
  data() {
    return {
      showSidebar: false
    }
  },
  components: {
    Header,
    Sidebar,
    Footer
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    }
  }
}
</script>

<style>
  body {
    margin: 0;
    min-width: 900px;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #ddd;
    font-size: 17px;
  }

  .content {
    margin: 66px 10% 0px;
    background: #fff;
    min-height: calc(100vh - 150px);
    padding: 2px 20px;
  }

  .content > :first-child {
    position: relative;
    display: inline-block;
  }

  .content > a {
    display: block;
  }
</style>
