<template>
  <h3 class="pagetitle">Cartridge ROM Info</h3>
  <p>{{ console }}</p>
</template>

<script>
  export default {
    name: 'CartridgeROMInfo',
    props: ['console']
  }
</script>

<style scoped>
  .pagetitle {
    margin-bottom: 0
  }
</style>