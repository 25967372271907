<template>
  <header>
    <SidebarButton @toggled-sidebar="toggleSidebar" />
    <router-link to="/">
      <img alt="Logo" src="../assets/logo.png">
      <h1>{{title}}</h1>
    </router-link>
  </header>
</template>

<script>
  import SidebarButton from './sidebar/SidebarButton'

  export default {
    name: 'Header',
    props: {
      title: String,
    },
    components: {
      SidebarButton
    },
    methods: {
      toggleSidebar() {
        this.$emit('toggled-sidebar')
      }
    }
  }
</script>

<style scoped>
  header {
    width: 100%;
    background: #f3f1f3;
    text-align: left;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  header img {
    height: 50px;
    display: inline;
    position: absolute;
    top: 8px;
    margin-left: 10px;
  }

  header a:link,
  header a:visited,
  header a:active,
  header a:hover {
    text-decoration: none;
    display: inline-block;
    height: 50px;
  }
  h1 {
    display: inline;
    top: 12px;
    position: relative;
    color: #222;
    text-decoration: none !important;
    margin: 0 0 0 80px;
    padding: 20px 0 10px;
  }
</style>